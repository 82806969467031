import { TAgentPreference, TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';
import { AgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';
import { createContext, useContext } from 'react';

export type TPreferencesContext = {
  state: {
    preferenceModalOpen: boolean;
    agentPreference: TAgentPreference;
  };
  actions: {
    setPreferenceModalOpen: (openValue: boolean) => void;
    updatePreference: (agentPreference: TUpdateAgentPreferenceBodySchema) => Promise<void>;
  };
};

const Context = createContext<TPreferencesContext>({
  state: {
    preferenceModalOpen: false,
    agentPreference: {
      agentId: '',
      tenantId: '',
      emailSignature: '',
      emailSignatureInsetOn: AgentEmailSignatureInsertOn.NEVER,
      createdAt: '',
      updatedAt: ''
    }
  },
  actions: {
    setPreferenceModalOpen: () => {},
    updatePreference: async () => {}
  }
});

export default Context;

export const useAgentPreferencesContext = () => useContext(Context);
