import cx from 'classnames';
import { Letter } from 'react-letter';
import Text from 'lib/common/components/Text';
import SignatureInsertSettings from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/ViewEmailSignatureModal/SignatureInsertSettings';
import ViewEmailSignatureActions from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/ViewEmailSignatureModal/ViewEmailSignatureActions';
import styles from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/email-signature-modal.module.scss';
import { TSignatureViewParams } from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/typesEmailSignatureModal';

const ViewEmailSignatureModal = ({
  setEditMode,
  emailSignature,
  emailSignatureInsertOn,
  updatePreference
}: TSignatureViewParams) => {
  const hasEmailSignature = emailSignature !== '' && emailSignature !== undefined;
  const signaturePreviewContent = hasEmailSignature ? emailSignature : 'No Signature';

  return (
    <div className={styles['email-signature-modal__preview']}>
      <div className={styles['email-signature-modal__preview__content']}>
        <div className={styles['email-signature-modal__header']}>
          <Text type="heading3">Preview</Text>
          <ViewEmailSignatureActions
            hasEmailSignature={hasEmailSignature}
            updatePreference={updatePreference}
            setEditMode={setEditMode}
          />
        </div>
        <Letter
          html={signaturePreviewContent}
          allowedSchemas={['http', 'https', 'mailto', 'data']}
          className={cx(styles['email-signature-modal__content'], {
            [styles['email-signature-modal__content--disabled']]: !hasEmailSignature
          })}
        />
      </div>
      <SignatureInsertSettings
        hasEmailSignature={hasEmailSignature}
        emailSignatureInsertOn={emailSignatureInsertOn}
        updatePreference={updatePreference}
      />
    </div>
  );
};

export default ViewEmailSignatureModal;
