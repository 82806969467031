import Modal from 'lib/common/components/Modal';
import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext/Context';
import { useEffect, useState } from 'react';
import { useLayout } from '../../layout/LayoutContext';
import SoftphoneModal from '../../SoftphoneModalContext/components/SoftphoneModal';
import ViewEmailSignatureModal from './ViewEmailSignatureModal';
import EditEmailSignatureModal from './EditEmailSignatureModal';

import styles from './email-signature-modal.module.scss';

interface IEmailSignatureModal {
  open: boolean;
  closeModal: () => void;
}

const EmailSignatureModal = ({ open, closeModal }: IEmailSignatureModal) => {
  const [editMode, setEditMode] = useState(false);
  const {
    state: { agentPreference },
    actions: { updatePreference }
  } = useAgentPreferencesContext();
  const { isSoftphone } = useLayout();
  const [emailSignature, setEmailSignature] = useState(agentPreference?.emailSignature || '');

  const titleWord = !editMode ? 'Manage' : agentPreference?.emailSignature ? 'Edit' : 'Create';

  useEffect(() => {
    setEmailSignature(agentPreference?.emailSignature || '');
    setEditMode(false);
  }, [agentPreference]);

  const modalContent = editMode ? (
    <EditEmailSignatureModal
      setEditMode={setEditMode}
      emailSignature={emailSignature}
      setEmailSignature={setEmailSignature}
    />
  ) : (
    <ViewEmailSignatureModal
      emailSignatureInsertOn={agentPreference?.emailSignatureInsetOn}
      setEditMode={setEditMode}
      emailSignature={emailSignature}
      updatePreference={updatePreference}
    />
  );

  const softphoneButtons = editMode
    ? {
        onCancel: () => {
          setEmailSignature(agentPreference?.emailSignature || '');
          setEditMode(false);
        },
        onSave: async () => {
          await updatePreference({ emailSignature });
        },
        onSuccess: () => {
          setEditMode(false);
        },
        secondaryButtonText: 'Back'
      }
    : {};

  return isSoftphone ? (
    <SoftphoneModal
      title={'Email Preferences'}
      isModalOpen={open}
      handleClearContent={() => {}}
      isReversedLayout={false}
      {...softphoneButtons}
      closeModal={() => {
        closeModal();
        setEditMode(false);
      }}
    >
      <div className={styles['email-signature-modal']}>{modalContent}</div>
    </SoftphoneModal>
  ) : (
    <Modal
      large
      open={open}
      onClose={() => {
        closeModal();
        setEditMode(false);
      }}
      onSecondary={() => {
        setEmailSignature(agentPreference?.emailSignature || '');
        setEditMode(false);
      }}
      secondaryButtonText="Back"
      hideFooter={!editMode}
      onSave={async () => {
        await updatePreference({ emailSignature });
      }}
      onSuccess={() => {
        setEditMode(false);
      }}
      title={`${titleWord} Your Signature`}
    >
      <div className={styles['email-signature-modal']}>{modalContent}</div>
    </Modal>
  );
};

export default EmailSignatureModal;
