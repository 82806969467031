import { faUnicorn } from '@fortawesome/pro-regular-svg-icons';
import Text from 'lib/common/components/Text';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import styles from './playground.module.scss';

export default function Playground() {
  return (
    <div className={styles['playground']}>
      <Text type="heading1">Playground</Text>
      <Text type="label">
        This page is for developers to test and debug issues. This{' '}
        <Text inline bold>
          should not be visible in production
        </Text>{' '}
        but should be visible in other environments.
      </Text>
      <EmptyPlaceholder icon={faUnicorn} subText="You can add any component that might tickle your fancy." />
    </div>
  );
}
