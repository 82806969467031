import { TAgentEmailSignatureInsertOn, TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';
import { AgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';
import { faEnvelope, faPaperPlane, faReply } from '@fortawesome/pro-regular-svg-icons';
import Checkbox from 'lib/common/components/atoms/Checkbox';
import SegmentedControls from 'lib/common/components/SegmentedControls/UncontrolledSegmentedControls';
import styles from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/email-signature-modal.module.scss';
import { useState } from 'react';

const SignatureInsertSettings = ({
  hasEmailSignature,
  emailSignatureInsertOn,
  updatePreference
}: {
  hasEmailSignature: boolean;
  emailSignatureInsertOn?: TAgentEmailSignatureInsertOn;
  updatePreference: (agentPreference: TUpdateAgentPreferenceBodySchema) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const insertOptions = [
    { icon: faEnvelope, label: 'All Emails', value: AgentEmailSignatureInsertOn.All },
    { icon: faPaperPlane, label: 'New Emails', value: AgentEmailSignatureInsertOn.NEW },
    { icon: faReply, label: 'Replies/Forwards', value: AgentEmailSignatureInsertOn.REPLY }
  ];
  const selectedOption = insertOptions.findIndex(({ value }) => {
    return value === emailSignatureInsertOn;
  });

  const EmailSetActions =
    emailSignatureInsertOn !== AgentEmailSignatureInsertOn.NEVER && hasEmailSignature ? (
      <SegmentedControls
        ariaLabel="Contact type"
        small
        initialIndices={selectedOption > 0 ? selectedOption : 0}
        onSelect={async (tabName: string) => {
          setLoading(true);
          await updatePreference({ emailSignatureInsetOn: tabName as TAgentEmailSignatureInsertOn });
          setLoading(false);
        }}
        disabled={loading}
        options={insertOptions}
      />
    ) : null;
  return (
    <div className={styles['email-signature-modal__insert']}>
      <Checkbox
        label="Insert my signature automatically"
        id="IOA"
        disabled={!hasEmailSignature || loading}
        checked={
          hasEmailSignature &&
          emailSignatureInsertOn !== AgentEmailSignatureInsertOn.NEVER &&
          emailSignatureInsertOn !== undefined
        }
        onChange={async (checked) => {
          const emailSignatureInsetOnNew = checked
            ? AgentEmailSignatureInsertOn.All
            : AgentEmailSignatureInsertOn.NEVER;
          setLoading(true);

          await updatePreference({ emailSignatureInsetOn: emailSignatureInsetOnNew });

          setLoading(false);
        }}
      />
      {EmailSetActions}
    </div>
  );
};

export default SignatureInsertSettings;
