import { TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';
import { getAgentPreference } from 'lib/common/contexts/AgentPreferenceContext/api/getAgentPreference';
import { updateAgentPreference } from 'lib/common/contexts/AgentPreferenceContext/api/updateAgentPreference';
import toast from 'lib/common/utils/toast';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useConfigContext } from 'lib/core/config';
import { useState } from 'react';

import Context from 'lib/common/contexts/AgentPreferenceContext/Context';

const cacheKey = 'agentPreference';

/** This context is only for global preferences that are needed by multiple components.
 * Other agent preferences can be found in the preferences overlay **/
function AgentPreferencesProvider({ children }) {
  const queryClient = useQueryClient();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  // Queries
  const { data: agentPreference } = useQuery({
    queryKey: [cacheKey],
    queryFn: () => {
      return getAgentPreference({ config, fetch_ });
    }
  });

  const [preferenceModalOpen, setPreferenceModalOpen] = useState(false);

  const { mutateAsync: updateAgentPreferenceMutation } = useMutation({
    mutationFn: updateAgentPreference,
    onSuccess: () => queryClient.invalidateQueries([cacheKey]),
    onError: () => {
      toast('error', 'failed to update agent preferences');
    }
  });

  return (
    <Context.Provider
      value={{
        state: { agentPreference, preferenceModalOpen },
        actions: {
          updatePreference: async (agentPreference: TUpdateAgentPreferenceBodySchema) => {
            await updateAgentPreferenceMutation({
              agentPreference,
              config,
              fetch_
            });
          },
          setPreferenceModalOpen
        }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default AgentPreferencesProvider;
