import RichTextEditor from 'lib/common/components/molecules/RichTextEditor';
import { EditorUseCase } from 'lib/common/components/molecules/RichTextEditor/Editor';
import { TSignatureEditParams } from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/typesEmailSignatureModal';

import styles from './email-signature-modal.module.scss';

const EditEmailSignatureModal = ({ setEmailSignature, emailSignature }: TSignatureEditParams) => {
  return (
    <>
      <RichTextEditor.LexicalComposer initialConfig={RichTextEditor.defaultEditorConfig}>
        <div className={styles['email-signature']}>
          <RichTextEditor.Toolbar className={'email-signature__toolbar'} open={true} invertLocation />
          <RichTextEditor.Editor
            className={styles['email-signature__editor']}
            placeHolder={''}
            editorUseCase={EditorUseCase.EmailMessage}
            onSubmit={() => {}}
            onHtmlChange={(emailContent) => {
              setEmailSignature(emailContent);
            }}
            content={emailSignature}
            html={emailSignature}
          />
        </div>
      </RichTextEditor.LexicalComposer>
    </>
  );
};

export default EditEmailSignatureModal;
