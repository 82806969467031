import { TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';
import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import ClickableIcon from 'lib/common/components/ClickableIcon';

import styles from 'lib/common/contexts/AgentPreferenceContext/EmailSignatureModal/email-signature-modal.module.scss';

const ViewEmailSignatureActions = ({
  hasEmailSignature,
  updatePreference,
  setEditMode
}: {
  hasEmailSignature: boolean;
  setEditMode: (value: boolean) => void;
  updatePreference: (agentPreference: TUpdateAgentPreferenceBodySchema) => Promise<void>;
}) => {
  const existingActions = (
    <>
      <ClickableIcon
        icon={faTrash}
        color="danger"
        size={17}
        asyncAction
        onClick={async () => await updatePreference({ emailSignature: '' })}
      />
      <ClickableIcon
        icon={faEdit}
        size={17}
        onClick={() => {
          setEditMode(true);
        }}
      />
    </>
  );

  const noSignature = (
    <ClickableIcon
      icon={faPlus}
      size={17}
      onClick={() => {
        setEditMode(true);
      }}
    />
  );
  return (
    <div className={styles['email-signature-modal__actions']}>{hasEmailSignature ? existingActions : noSignature}</div>
  );
};

export default ViewEmailSignatureActions;
